<template>
  <div v-loading.lock="visibleSpin">
    <div>
      <div v-show="!visibleSpin" class="homePage">
        <div
          v-for="(content, index) in homeContent"
          :key="
            Math.ceil(
              Math.random() * 10 + Math.random() * 100 + Math.random() * 1000
            ) + content.VALEUR_ID
          "
          class="homePage-content"
        >
          <div v-if="content.ACTIF">
            <div class="homePage-content-title">
              <img
                v-if="content.MEMOS[0]"
                :src="`data:image/png;base64,${content.MEMOS[0]}`"
              />
              <h1>{{ content.CHAINES[0] }}</h1>
            </div>
            <span v-html="content.MEMOS[1]" />
            <hr v-if="index < homeContent.length - 1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import CircleChartCard from "src/components/UIComponents/Cards/CircleChartCard.vue";
import ChartCard from "src/components/UIComponents/Cards/ChartCard";
import StatsCard from "src/components/UIComponents/Cards/StatsCard";
import { Badge } from "src/components/UIComponents";
import Loading from "src/components/Dashboard/Layout/LoadingMainPanel.vue";
import TaskList from "./Widgets/TaskList";
const WorldMap = () => ({
  component: import("./../Maps/WorldMap.vue"),
  loading: Loading,
  delay: 200,
});

export default {
  components: {
    StatsCard,
    ChartCard,
    CircleChartCard,
    WorldMap,
    Badge,
    TaskList,
  },
  methods: {
    ...mapActions(["removeToken"]),
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      visibleSpin: true,
      homeContent: null,
    };
  },
  async mounted() {
    this.visibleSpin = true;
    const token = JSON.parse(sessionStorage.getItem("tokenInfos"));
    if (token) {
      const date = new Date(token.EXPIRATION).getTime();
      const now = new Date().getTime();
      if (date <= now) {
        alert("Token expiré. \nVeuillez vous reconnecter.");
        this.removeToken("tokenInfos");
        this.$router.push("/login", () => {});
      } else {
        console.log("entrée dans overview");
        const titleHeader = {
          title: "Accueil",
          subTitle: "",
          icon:
            '<i aria-label="icon: home" class="anticon anticon-home"><svg viewBox="64 64 896 896" data-icon="home" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M946.5 505L560.1 118.8l-25.9-25.9a31.5 31.5 0 0 0-44.4 0L77.5 505a63.9 63.9 0 0 0-18.8 46c.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8a63.6 63.6 0 0 0 18.7-45.3c0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204zm217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z"></path></svg></i>',
        };
        await this.$store.dispatch("setHeaderTitle", titleHeader);

        const token = JSON.parse(sessionStorage.getItem("tokenInfos")).TOKEN;
        const answer = await this.$store.dispatch("getHomeContent", token);
        this.$notify({
          type: answer.type,
          title: answer.title,
          message: answer.description,
          icon: answer.icon,
          horizontalAlign: "right",
          verticalAlign: "top",
        });
        let content = JSON.parse(localStorage.getItem("homeContent"));
        content.sort((a, b) =>
          a.VALEUR_ID !== b.VALEUR_ID ? (a.VALEUR_ID < b.VALEUR_ID ? -1 : 1) : 0
        );
        this.homeContent = content;
        this.visibleSpin = false;
      }
    } else {
      this.$router.push("/login", () => {});
    }
  },
};
</script>
<style lang="scss">
.homePage {
  color: #000;
  width: 100%;
  margin: auto;
  padding: 32px;
  &-content {
    margin-bottom: 32px;
    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 80px;
        width: auto;
        margin-right: 25px;
      }
    }
  }
}
h1 {
  font-size: 32px;
  text-align: center;
}
p,
li {
  font-size: 16px;
  text-align: justify;
}
</style>
